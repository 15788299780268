import React from "react";
import ReactMarkdown from "react-markdown";

const RadioInput = (props: RadioGroupProps) => {
    return (
        <div className="field">
            <div className="ui radio checkbox" onClick={() => props.onClick()}>
                <input
                    aria-label={props["aria-label"]}
                    name={props.name}
                    readOnly
                    tabIndex={0}
                    type="radio"
                    checked={props.checked}
                />
                <label>
                    <ReactMarkdown linkTarget="_blank">
                        {props.label}
                    </ReactMarkdown>
                </label>
            </div>
        </div>
    )
}

interface RadioGroupProps {
    "aria-label": string
    key: string
    label: string
    name: string
    value: string
    checked: boolean
    onClick: any
    disabled: boolean
}

export default RadioInput
