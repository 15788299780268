import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { Button, Form, Grid, Header, Message, Segment } from "semantic-ui-react";
import ReactMarkdown from "react-markdown";
import AppContext from "../contexts/AppContext";
import {
    VaccineLatestDateQuestion,
    JurisdictionQuestion,
    PediatricQuestion,
    VaccineNameQuestionPediatric,
    IsRepresentativeQuestion,
} from "./base/Questions";
import * as Yup from "yup";
import moment from "moment";
import { useHistory } from "react-router";
import { UrlHelper } from "../shared/url.helper";

interface FormData {
    jurisdiction: string;
    pediatricQuestion: "yes" | "no" | "";
    isRepresentative: "yes" | "no" | "";
    vaccineName: string;
    vaccineLatestDate: Date;
    attestationChecked: boolean;
}

export function PediatricDose() {
    const history = useHistory();
    const [disabled, setDisabled] = useState(false);

    const [message, setMessage] = useState<{
        header: string;
        body: string;
        confirmation?: string;
        showOverwrite?: boolean;
        error?: boolean;
        success?: boolean;
        warning?: boolean;
    }>(null);

    const ctx = useContext(AppContext);

    const onSubmit = async (values: FormData) => {
        try {
            setDisabled(true);
            setMessage(null);

            if (values.vaccineName === "janssen" && values.pediatricQuestion === "yes") {
                const qStr = UrlHelper.upsertParams({
                    vaccinename: values.vaccineName,
                });
                history.push(`/singleDoseVaccine${qStr}`);
                return;
            }
            const url = new URL(ctx.config.guestUrl);

            url.searchParams.append("jurisdiction", values.jurisdiction);

            let dose = values.pediatricQuestion === "no" ? "1" : "2";

            url.searchParams.append("dose", dose);
            url.searchParams.append("ageCategory", "minor");

            // Only send vaccine info if we're on dose 2 or more
            if (parseInt(dose) >= 2) {
                url.searchParams.append("vaccinename", values.vaccineName);
                url.searchParams.append("initialvaccinationdate", moment(values.vaccineLatestDate).format("L"));
            }

            url.searchParams.append("language", ctx.lang === "en" ? "en_US" : ctx.lang);

            window.location.href = url.toString();
        } catch (error) {
            console.log(error);
            setMessage({ error: true, header: "Error", body: error.message });
            setDisabled(false);
        }
    };

    const defaultValues = (): FormData => {
        return {
            jurisdiction: ctx.jurisdiction.id,
            pediatricQuestion: "",
            isRepresentative: "",
            vaccineName: "",
            vaccineLatestDate: undefined,
            attestationChecked: false,
        };
    };

    const formik = useFormik({
        initialValues: defaultValues(),
        validationSchema: Yup.object({
            jurisdiction: Yup.string().required(ctx.getLabel("requiredFieldError")),
            vaccineName: Yup.string()
                .when("pediatricQuestion", {
                    is: "yes",
                    then: Yup.string().required(ctx.getLabel("vaccineDateRequiredError")),
                })
                .when("pediatricQuestion", {
                    is: "yes",
                    then: Yup.string().oneOf(
                        [
                            "Peds Pfizer-BioNTech COVID-19 Vaccine (ages 5-11)",
                            "Pfizer-BioNTech (COMIRNATY) COVID-19 Vaccine",
                            "Moderna COVID-19 Vaccine",
                            "janssen",
                            "dont-remember",
                        ],
                        ctx.getLabel("boosterWrongVaccineError")
                    ),
                }),
            vaccineLatestDate: Yup.string().when("pediatricQuestion", {
                is: "yes",
                then: Yup.string().required(ctx.getLabel("vaccineDateRequiredError")),
            }),
            attestationChecked: Yup.boolean().required(ctx.getLabel("requiredFieldError")),
        }),
        onSubmit: onSubmit,
    });

    const pediatricNote = () => (
        <div>
            <ReactMarkdown linkTarget="_blank">{ctx.getLabel("pediatricDoseNote")}</ReactMarkdown>
            <ul>
                <li>
                    <ReactMarkdown linkTarget="_blank">{ctx.getLabel("pediatricDescOne")}</ReactMarkdown>
                </li>
                <li>
                    <ReactMarkdown linkTarget="_blank">{ctx.getLabel("pediatricDescTwo")}</ReactMarkdown>
                </li>
            </ul>
        </div>
    );

    const attestationText = () => {
        return ctx.getLabel("attestation");
        // switch (formik.values.doseType) {
        //     case "additional":
        //         return ctx.getLabel("attestationAdditionalDose");
        //     case "booster":
        //         return ctx.getLabel("attestationBoosterlDose");
        //     default:
        //         return ctx.getLabel("attestation");
        // }
    };

    // const timeRangeValidation = (vaccineLatestDate: any, vaccineName: string) => {
    //     if (vaccineLatestDate) {
    //         switch (vaccineName) {
    //             case "Peds Pfizer-BioNTech COVID-19 Vaccine (ages 5-11)":
    //                 return null;
    //             case "Pfizer-BioNTech (COMIRNATY) COVID-19 Vaccine":
    //             case "Moderna COVID-19 Vaccine":
    //                 return (
    //                     <Message
    //                         icon="info circle"
    //                         warning
    //                         visible={
    //                             formik.values.pediatricQuestion === "yes" &&
    //                             moment(vaccineLatestDate).isAfter(moment().add(-180, "days"))
    //                         }
    //                     >
    //                         {ctx.getLabel("boosterDateWarning")}
    //                     </Message>
    //                 );
    //             case "janssen":
    //                 return (
    //                     <Message
    //                         icon="info circle"
    //                         warning
    //                         visible={
    //                             formik.values.pediatricQuestion === "yes" &&
    //                             moment(vaccineLatestDate).isAfter(moment().add(-60, "days"))
    //                         }
    //                     >
    //                         {ctx.getLabel("boosterDateWarning")}
    //                     </Message>
    //                 );
    //             default:
    //                 return null;
    //         }
    //     }
    // };

    return (
        <Grid container>
            <Grid.Row>
                <Grid.Column>
                    <Header as="h1">{ctx.getJurisdictionLabel("welcome")}</Header>
                    <Message icon="info circle" content={pediatricNote()} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Form success className="eligibility-form" onSubmit={formik.handleSubmit}>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Segment padded>
                                        <Grid columns={1}>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <JurisdictionQuestion
                                                        formik={formik as any}
                                                        disabled={disabled}
                                                        tabIndex={1}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <PediatricQuestion
                                                        formik={formik as any}
                                                        disabled={disabled}
                                                        tabIndex={2}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            {formik.values.pediatricQuestion === "yes" ? (
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <VaccineNameQuestionPediatric
                                                            formik={formik as any}
                                                            disabled={disabled}
                                                            tabIndex={3}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            ) : null}
                                            {formik.values.pediatricQuestion === "yes" ? (
                                                <React.Fragment>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            {/* {timeRangeValidation(
                                                                formik.values.vaccineLatestDate,
                                                                formik.values.vaccineName
                                                            )} */}

                                                            <VaccineLatestDateQuestion
                                                                formik={formik as any}
                                                                disabled={disabled}
                                                                tabIndex={4}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </React.Fragment>
                                            ) : null}
                                            {ctx.jurisdiction.enableAuthRep && (
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <IsRepresentativeQuestion
                                                            formik={formik as any}
                                                            disabled={disabled}
                                                            tabIndex={4}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}
                                            {(formik.values.pediatricQuestion === "yes" ||
                                                formik.values.pediatricQuestion === "no") && (
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Form.Checkbox
                                                            label={attestationText()}
                                                            aria-label={attestationText()}
                                                            disabled={disabled}
                                                            checked={formik.values.attestationChecked}
                                                            onClick={async () => {
                                                                if (!formik.isValid) {
                                                                    const errorFields = {} as any;
                                                                    Object.keys(formik.errors).forEach(
                                                                        (key) => (errorFields[key] = true)
                                                                    );
                                                                    formik.setTouched(errorFields, true);
                                                                }
                                                                formik.setFieldValue(
                                                                    "attestationChecked",
                                                                    !formik.values.attestationChecked
                                                                );
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}

                                            {message && (
                                                <Message
                                                    visible={message ? true : false}
                                                    error={message?.error}
                                                    success={message?.success}
                                                >
                                                    <Message.Header>{message?.header}</Message.Header>
                                                    {message?.body && <p>{message.body}</p>}
                                                </Message>
                                            )}
                                        </Grid>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column textAlign={"right"}>
                                    <Button primary disabled={!formik.values.attestationChecked} type="submit">
                                        {ctx.getLabel("next")}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
