import { Formik, getIn } from "formik";
import React, { useContext, useState } from "react";
import { Form, InputOnChangeData, SemanticWIDTHS } from "semantic-ui-react";
import { AppCheckBoxRadio, AppDate, AppDropdown, AppRadioGroup, IOption } from "./AppFormInputs";
import { AppLabel } from "./AppLabel";
import AppContext from "../../contexts/AppContext";
import moment from "moment";

const getErrorText = (formik: any, name: string): any => {
    return getIn(formik.touched, name) && getIn(formik.errors, name) ? getIn(formik.errors, name) : null;
};

interface IQuestionProps {
    disabled?: boolean;
    formik: {
        handleChange: (e: any) => void;
        handleBlur: (e: any) => void;
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void>;
        setFieldError: (field: string, value: string) => void;
        values: { [name: string]: any };
    };
    tabIndex: number;
    vaccineName?: string;
}

interface ITextInputQuestionProps extends IQuestionProps {
    name: string;
    label?: string;
    subLabel?: string;
    required?: boolean;
    width?: SemanticWIDTHS;
}
export function TextInputQuestion(props: ITextInputQuestionProps) {
    const error = getErrorText(props.formik, props.name);

    const handleChange = (e: any, data: InputOnChangeData) => {
        props.formik.setFieldValue(props.name, data.value);
    };

    return (
        <Form.Input
            key={props.name}
            aria-label={props.label}
            label={
                (props.label || props.subLabel) && (
                    <AppLabel
                        label={props.label}
                        subLabel={props.subLabel}
                        required={props.required}
                        className="field-label"
                    />
                )
            }
            name={props.name}
            onChange={handleChange}
            value={props.formik.values[props.name] || ""}
            disabled={props.disabled}
            error={error && { content: error }}
            width={props.width}
        />
    );
}

export function JurisdictionQuestion(props: IQuestionProps) {
    const name = "jurisdiction";

    const ctx = useContext(AppContext);
    const [options] = useState(ctx.config.getJurisdictionList());
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppDropdown
            name={name}
            label={questionConfig.label}
            note={questionConfig.subLabel.replace("REPLACE_FIND_VACCINE_URL", ctx.config.findVaccine)}
            options={options}
            disabled={props.disabled}
            value={props.formik.values[name]}
            onChanged={(value) => {
                props.formik.setFieldValue(name, value);
            }}
            error={getErrorText(props.formik, name)}
            required={true}
            tabIndex={props.tabIndex}
        />
    );
}

export function VaccineDateQuestion(props: IQuestionProps) {
    const name = "vaccineDate";

    const value = props.formik.values[name];
    const vaccineName = props.formik.values.vaccineName;

    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    let error = getErrorText(props.formik, name);
    if (!error && value && vaccineName) {
        const vaccine = ctx.config.vaccines.find((x) => x.value === vaccineName);
        const approved = vaccine?.approved;
        if (approved) {
            const approvedDate = moment(approved);
            if (moment(value, "M/D/YYYY").isBefore(approvedDate)) {
                const date = moment(approved).format("MM/DD/YY");
                const vaccineName = vaccine[ctx.lang];
                error = ctx
                    .getLabel("vaccineDateError")
                    .replace("REPLACE_DATE", date)
                    .replace("REPLACE_VACCINE_NAME", vaccineName);
            }
        }
    }

    return (
        <AppDate
            name={name}
            label={questionConfig.label}
            subLabel={questionConfig.subLabel}
            disabled={props.disabled}
            value={value}
            onChanged={(value) => props.formik.setFieldValue(name, value)}
            error={error}
            required={true}
            tabIndex={props.tabIndex}
            locale={ctx.lang}
        />
    );
}

export function VaccineLatestDateQuestion(props: IQuestionProps) {
    const name = "vaccineLatestDate";

    const value = props.formik.values[name];
    const vaccineName = props.formik.values.vaccineName;

    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    let error = getErrorText(props.formik, name);
    if (!error && value && vaccineName) {
        const vaccine = ctx.config.vaccines.find((x) => x.value === vaccineName);
        const approved = vaccine?.approved;
        if (approved) {
            const approvedDate = moment(approved);
            if (moment(value, "M/D/YYYY").isBefore(approvedDate)) {
                const date = moment(approved).format("MM/DD/YY");
                const vaccineName = vaccine[ctx.lang];
                error = ctx
                    .getLabel("vaccineDateError")
                    .replace("REPLACE_DATE", date)
                    .replace("REPLACE_VACCINE_NAME", vaccineName);
            }
        }
    }

    return (
        <AppDate
            name={name}
            label={questionConfig.label}
            subLabel={questionConfig.subLabel}
            disabled={props.disabled}
            value={value}
            onChanged={(value) => props.formik.setFieldValue(name, value)}
            error={error}
            required={true}
            tabIndex={props.tabIndex}
            locale={ctx.lang}
        />
    );
}

export function IsRepresentativeQuestion(props: IQuestionProps) {
    const name = "isRepresentative";

    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function VaccineReceivedQuestion(props: IQuestionProps) {
    const name = "vaccineReceived";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);
    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={questionConfig.subLabel}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function PediatricQuestion(props: IQuestionProps) {
    const name = "pediatricQuestion";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);
    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={questionConfig.subLabel}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function AdditionalDoseScheduledQuestion(props: IQuestionProps) {
    const name = "doseType";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);
    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            note={questionConfig.subLabel}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function VaccineNameQuestion(props: IQuestionProps) {
    const name = "vaccineName";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={ctx.config.vaccines.map((x: any) => ({ text: x[ctx.lang], value: x.value }))}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function VaccineNameQuestionPediatric(props: IQuestionProps) {
    const name = "vaccineName";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={[
                { text: ctx.config.vaccines[0][ctx.lang], value: ctx.config.vaccines[0].value },
                { text: ctx.config.vaccines[4][ctx.lang], value: ctx.config.vaccines[4].value },
            ]}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function SingleDoseVaccineQuestion(props: IQuestionProps) {
    const name = "singleDoseConfirm";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);
    const opts = questionConfig.options.map((opt) => {
        opt.text = opt.text.replace("REPLACE_VACCINE_NAME", props.vaccineName);
        return opt;
    });
    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={opts}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function ApptTypeQuestion(props: IQuestionProps) {
    const name = "apptType";

    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);

    return (
        <AppRadioGroup
            name={name}
            label={questionConfig.label}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}

export function VaccineICQuestion(props: IQuestionProps) {
    const name = "vaccineICName";
    const ctx = useContext(AppContext);
    const questionConfig = ctx.getQuestion(name);
    return (
        <AppCheckBoxRadio
            name={name}
            label={questionConfig.label}
            note={questionConfig.subLabel}
            required={true}
            value={props.formik.values[name]}
            error={getErrorText(props.formik, name)}
            onChanged={(val) => props.formik.setFieldValue(name, val)}
            options={questionConfig.options}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}
