export interface IMultiLangLabel {
    en: string;
    es: string;
}
interface IMultiLangOption extends IMultiLangLabel {
    value: string;
}
interface IQuestionText {
    label: string;
    subLabel?: string;
    subLabelLink?: string;
}

interface IQuestionDefinition {
    en: IQuestionText;
    es: IQuestionText;
    options: IMultiLangOption[];
}

export type LanguageId = "en" | "es";

export type QuestionId =
    | "jurisdiction"
    | "singleDoseConfirm"
    | "vaccineReceived"
    | "pediatricQuestion"
    | "doseType"
    | "vaccineDate"
    | "vaccineLatestDate"
    | "isRepresentative"
    | "vaccineName"
    | "vaccineICName"
    | "apptType";

interface IQuestions {
    jurisdiction: IQuestionDefinition;
    singleDoseConfirm: IQuestionDefinition;
    vaccineReceived: IQuestionDefinition;
    pediatricQuestion: IQuestionDefinition;
    doseType: IQuestionDefinition;
    vaccineDate: IQuestionDefinition;
    vaccineLatestDate: IQuestionDefinition;
    vaccineName: IQuestionDefinition;
    vaccineICName: IQuestionDefinition;
    isRepresentative: IQuestionDefinition;
    apptType: IQuestionDefinition;
}

export const Questions: IQuestions = {
    jurisdiction: {
        en: {
            label: "Select the state you want to get vaccinated in",
            subLabel:
                "Note: If you do not see the state or jurisdiction your are looking for, it may not be using VAMS. [Click here to find out more](REPLACE_FIND_VACCINE_URL?language=en_US).",
        },
        es: {
            label: "Seleccione el estado en el que desea vacunarse",
            subLabel:
                "Nota: si no ve el estado o la jurisdicción que está buscando, quizás no estén usando el VAMS. [Haga clic aquí para obtener más información](REPLACE_FIND_VACCINE_URL?language=es).",
        },
        options: [],
    },
    singleDoseConfirm: {
        en: {
            label: "Is this correct?",
        },
        es: {
            label: "¿Es esto correcto?",
        },
        options: [
            {
                en: `Yes, the REPLACE_VACCINE_NAME was administered and the vaccination is complete.`,
                es: "Sí, la vacuna contra el REPLACE_VACCINE_NAME fue administrada y la vacunación está completa.",
                value: "yes",
            },
            {
                en: "No, a different vaccine was administered.",
                es: "No, se administró una vacuna diferente.",
                value: "no",
            },
        ],
    },
    vaccineReceived: {
        en: {
            label: "Have you ever received a dose of COVID-19 vaccine?",
            subLabel: "Note: This is in reference to vaccination, not to any COVID-19 testing you may have received.",
        },
        es: {
            label: "¿Ha recibido alguna vez una vacuna contra el COVID-19?",
            subLabel:
                "Nota: esto se refiere a la vacunación, no a las pruebas del COVID-19 que se le pueden haber hecho.",
        },
        options: [
            { value: "yes", en: "Yes", es: "Sí" },
            { value: "no", en: "No", es: "No" },
        ],
    },
    pediatricQuestion: {
        en: {
            label: "Has the minor recipient (ages 5-11) ever received a COVID-19 pediatric vaccine?",
            subLabel: "Note: This is in reference to vaccination, not to any COVID-19 testing you may have received.",
        },
        es: {
            label:
                "El menor (5 a 11 años) que recibirá la vacuna, ¿alguna vez recibió una vacuna pediátrica contra el COVID-19?",
            subLabel:
                "Nota: Para considerarse totalmente vacunado debe haber completado todas las dosis requeridas (es decir, 2 dosis de la vacuna Pfizer-BioNTech o de la vacuna Moderna)",
        },
        options: [
            {
                value: "yes",
                en: "Yes",
                es: "Sí",
            },
            {
                value: "no",
                en: "No",
                es: "No",
            },
        ],
    },

    doseType: {
        en: {
            label:
                "Are you fully vaccinated and trying to schedule a COVID-19 Booster dose based on the updated Federal guidance?",
            subLabel:
                "Note: In order to be considered fully vaccinated the recipient must have completed all required doses (i.e., 2 doses for Pfizer-BioNTech & Moderna and 1 dose for Janssen (Johnson & Johnson))",
        },
        es: {
            label:
                "¿Está completamente vacunado y trata de programar una cita para la dosis adicional o de refuerzo de la vacuna contra el COVID-19 según las directrices federales actualizadas?",
            subLabel:
                "Nota: Para considerarse totalmente vacunado debe haber completado todas las dosis requeridas (es decir, 2 dosis de la vacuna Pfizer-BioNTech o de la vacuna Moderna)",
        },
        options: [
            {
                value: "additional",
                en:
                    "Yes, schedule for COVID-19 vaccine **additional dose** appointment. [Click here to find out more](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html).",
                es:
                    "Sí, programe la cita para la **dosis adicional** de la vacuna contra el COVID-19. [Haga clic aquí para obtener más información](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html).",
            },
            {
                value: "booster",
                en:
                    "Yes, schedule for COVID-19 vaccine **booster dose** appointment. [Click here to find out more](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html).",
                es:
                    "Sí, programe la cita para la **dosis de refuerzo** de la vacuna contra el COVID-19. [Haga clic aquí para obtener más información](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html).",
            },
            {
                value: "no",
                en: "No, schedule for COVID-19 vaccine **first or second dose** appointment.",
                es: "No, programe la cita para la **primera o segunda dosis** de la vacuna contra el COVID-19.",
            },
        ],
    },
    vaccineDate: {
        en: {
            label: "Prior vaccination date",
            subLabel: "Format: m/d/yyyy",
        },
        es: {
            label: "Fecha de vacunación anterior",
            subLabel: "Formato: m/d/yyyy",
        },
        options: [],
    },
    vaccineLatestDate: {
        en: {
            label: "When did you receive the latest dose of COVID-19 vaccine?",
            subLabel: "Format: m/d/yyyy",
        },
        es: {
            label: "Fecha de vacunación anterior",
            subLabel: "Formato: m/d/yyyy",
        },
        options: [],
    },
    vaccineName: {
        en: {
            label: "Which vaccine did you receive?",
        },
        es: {
            label: "¿Cuál vacuna recibió?",
        },
        options: [],
    },
    isRepresentative: {
        en: {
            label: "Is this form being completed on the recipient’s behalf by a representative or guardian?",
        },
        es: {
            label: "NEED SPANISH",
        },
        options: [
            { value: "yes", en: "Yes", es: "Sí" },
            { value: "no", en: "No", es: "No" },
        ],
    },
    apptType: {
        en: {
            label: "Would you like to pre-register a walk-in appointment or schedule a future appointment?",
        },
        es: {
            label: "¿Le gustaría preinscribirse para ir sin cita o programar una cita futura?",
        },
        options: [
            {
                value: "preregister",
                en: "Pre-register for a walk-in appointment",
                es: "Preinscribirse para ir sin cita",
            },
            { value: "future", en: "Schedule a future appointment", es: "Programar una cita futura" },
        ],
    },
    vaccineICName: {
        en: {
            label: "Select the vaccine(s) you want to schedule an appointment for:",
        },
        es: {
            label: "Seleccione las vacunas que quiere programar:",
        },
        options: [
            { value: "COVID", en: "COVID-19 vaccine", es: "Vacuna contra el COVID-19" },
            { value: "Influenza", en: "Influenza vaccine", es: "Vacuna contra la influenza" },
            {
                value: "adult",
                en: "COVID-19 vaccine for adults (ages 12 and above)",
                es: "Vacuna COVID-19 para adultos (mayores de 12 años)",
            },
            {
                value: "minor",
                en: "COVID-19 vaccine for children (ages 5-11)",
                es: "Vacuna COVID-19 para niños (de 5 a 11 años)",
            },
        ],
    },
};

export interface IOption {
    value: string;
    text: string;
}
export interface IQuestionData {
    label: string;
    subLabel: string;
    subLabelLink: string;
    options: IOption[];
}

export function getQuestionForLanguage(id: QuestionId, lang: LanguageId = "en"): IQuestionData {
    const d = Questions[id];
    const labels = d[lang];
    const options = d.options?.map((o) => ({ value: o.value, text: o[lang] }));
    return {
        label: labels.label,
        subLabel: labels.subLabel,
        subLabelLink: labels.subLabelLink,
        options: options,
    };
}

export function getQuestionOptionValues(id: QuestionId, lang: LanguageId = "en"): string[] {
    return getQuestionForLanguage(id, lang).options.map((x) => x.value);
}

/**
 * Defines translation labels
 */
export type LabelId =
    | string
    | "jurisdiction"
    | "footerSupportLabel"
    | "footerInfoLabel"
    | "loginCtaHeader"
    | "guestCtaHeader"
    | "singleDoseLabel"
    | "singleDoseSublabel"
    | "requiredFieldError"
    | "vaccineDateError"
    | "vaccineNameRequiredError"
    | "vaccineDateRequiredError"
    | "loginToVams"
    | "scheduleFirstAppt"
    | "scheduleFirstApptLabel"
    | "scheduleVaccinationAppt"
    | "scheduleVaccinationApptLabel"
    | "schedulePediatricVaccinationAppt"
    | "schedulePediatricVaccinationApptLabel"
    | "scheduleSecondAppt"
    | "scheduleSecondApptLabel"
    | "scheduleAdditionalAppt"
    | "scheduleAdditionalApptLabel"
    | "manageAppts"
    | "manageApptsLabel"
    | "noappointment"
    | "urlnoappt"
    | "guestWelcome"
    | "cancel"
    | "confirm"
    | "vaccineComplete"
    | "vaccineCompleteDescription"
    | "closeBrowser"
    | "next"
    | "clinicVaccineError"
    | "additionalDoseBoosterNote"
    | "guestQuestionNote"
    | "guestQuestionOne"
    | "guestQuestionTwo"
    | "guestQuestionThree"
    | "guestQuestionFour";
interface ILabels {
    [x: string]: IMultiLangLabel;
    loginCtaHeader: IMultiLangLabel;
    footerSupportLabel: IMultiLangLabel;
    footerInfoLabel: IMultiLangLabel;
    guestCtaHeader: IMultiLangLabel;
    vaccineNameRequiredError: IMultiLangLabel;
    vaccineDateRequiredError: IMultiLangLabel;
    singleDoseLabel: IMultiLangLabel;
    singleDoseSublabel: IMultiLangLabel;
    requiredFieldError: IMultiLangLabel;
    vaccineDateError: IMultiLangLabel;
    jurisdiction: IMultiLangLabel;
    loginToVams: IMultiLangLabel;
    scheduleFirstAppt: IMultiLangLabel;
    scheduleFirstApptLabel: IMultiLangLabel;
    scheduleVaccinationAppt: IMultiLangLabel;
    scheduleVaccinationApptLabel: IMultiLangLabel;
    schedulePediatricVaccinationAppt: IMultiLangLabel;
    schedulePediatricVaccinationApptLabel: IMultiLangLabel;
    scheduleSecondAppt: IMultiLangLabel;
    scheduleSecondApptLabel: IMultiLangLabel;
    scheduleAdditionalAppt: IMultiLangLabel;
    scheduleAdditionalApptLabel: IMultiLangLabel;
    manageAppts: IMultiLangLabel;
    manageApptsLabel: IMultiLangLabel;
    noappointment: IMultiLangLabel;
    urlnoappt: IMultiLangLabel;
    guestWelcome: IMultiLangLabel;
    cancel: IMultiLangLabel;
    confirm: IMultiLangLabel;
    next: IMultiLangLabel;
    vaccineComplete: IMultiLangLabel;
    vaccineCompleteDescription: IMultiLangLabel;
    closeBrowser: IMultiLangLabel;
    clinicVaccineError: IMultiLangLabel;
    additionalDoseBoosterNote: IMultiLangLabel;
    guestQuestionNote: IMultiLangLabel;
}

const Labels: ILabels = {
    jurisdiction: {
        en: "Jurisdiction",
        es: "Jurisdicción",
    },
    footerSupportLabel: {
        en: "Have Questions?",
        es: "¿TIENE PREGUNTAS?",
    },
    footerInfoLabel: {
        en: "CDC INFORMATION",
        es: "INFORMACIÓN DE LOS CDC",
    },
    loginCtaHeader: {
        en: "If you have already created a username and password, click below to login.",
        es: "Si ya creó un nombre de usuario y una contraseña, haga clic abajo para ingresar.",
    },
    guestCtaHeader: {
        en: "If you have not created a username and password, select one of the following four options.",
        es: "Si no ha creado un nombre de usuario y una contraseña, seleccione una de las siguientes cuatro opciones.",
    },
    singleDoseLabel: {
        en:
            "The REPLACE_VACCINE_NAME is a one-dose vaccine. If it has already been received, then the vaccination is complete.",
        es:
            "La vacuna contra el REPLACE_VACCINE_NAME es una vacuna de una sola dosis. Si ya se recibió, la vacunación está completa.",
    },
    singleDoseSublabel: {
        en: "Note: If the REPLACE_VACCINE_NAME was received, new appointments cannot be scheduled in VAMS.",
        es:
            "Nota: Si ya se recibió la vacuna contra el REPLACE_VACCINE_NAME, no se pueden programar nuevas citas en el VAMS.",
    },
    requiredFieldError: {
        en: "This is a required field",
        es: "Este es un campo obligatorio",
    },
    vaccineNameRequiredError: {
        en: "Vaccine name is a required field",
        es: "El nombre de la vacuna es un campo obligatorio",
    },
    vaccineDateRequiredError: {
        en: "Vaccination date is a required field",
        es: "La fecha de vacunación es un campo obligatorio",
    },
    vaccineDateError: {
        en:
            "If you have already received a COVID-19 vaccine, the Prior Vaccination date should be between Emergency Use Authorization Approval date (REPLACE_DATE for REPLACE_VACCINE_NAME) and yesterday or today (assuming that you received your vaccination dose today). If you have NOT received a dose of COVID-19 Vaccine, please select 'No' in the question above.",
        es:
            "Si ya recibió una vacuna contra el COVID-19, la fecha de vacunación anterior debe ser entre la fecha de la Autorización de Uso de Emergencia (12/14/20 para Pfizer; 12/21/20 para Moderna) y ayer u hoy (suponiendo que recibió su dosis de la vacuna hoy). Si NO ha recibido una dosis de la vacuna contra el COVID-19, seleccione 'No' para responder la pregunta anterior.",
    },
    loginToVams: {
        en: "VAMS recipient login",
        es: "Ingreso al VAMS de la persona que recibe la vacuna",
    },
    scheduleFirstAppt: {
        en: "Schedule your 1st dose",
        es: "Programe su primera dosis",
    },
    scheduleFirstApptLabel: {
        en: "Schedule your **first appointment** by finding a clinic and a time slot that works for you.",
        es: "Encuentre un centro médico y un horario conveniente para programar su primera cita.",
    },
    scheduleSecondAppt: {
        en: "Schedule your 2nd dose",
        es: "Programe su segunda dosis",
    },
    scheduleSecondApptLabel: {
        en: "Schedule your **second appointment** by finding a clinic and a time slot that works for you.",
        es: "Encuentre un centro médico y un horario conveniente para programar su segunda cita.",
    },
    scheduleAdditionalAppt: {
        en: "Schedule your COVID-19 vaccine additional or booster dose",
        es: "Programe su dosis adicional o de refuerzo de la vacuna contra el COVID-19",
    },
    scheduleAdditionalApptLabel: {
        en:
            "Schedule your **additional dose** or **booster dose** appointment for COVID-19 vaccine by finding a clinic and a time slot that works for you.",
        es:
            "Programe la cita para su dosis adicional o de refuerzo de la vacuna contra el COVID-19 al encontrar un centro médico y un horario conveniente para usted.",
    },
    manageAppts: {
        en: "Manage Appointments",
        es: "Manejar citas",
    },
    manageApptsLabel: {
        en: "Manage a **current appointment** or update your information.",
        es: "Maneje una cita actual o actualice su información.",
    },
    guestWelcome: {
        en: "Proceed as Guest",
        es: "Continuar como huésped",
    },
    next: {
        en: "Next",
        es: "Siguiente",
    },
    cancel: {
        en: "Cancel",
        es: "Cancelar",
    },
    confirm: {
        en: "Confirm",
        es: "Confirmar",
    },
    vaccineComplete: {
        en: "Your vaccination is complete.",
        es: "Su vacunación está completa.",
    },
    vaccineCompleteDescription: {
        en:
            "You received the REPLACE_VACCINE_NAME. Because this is a one-dose vaccine, you have completed your vaccination and are not eligible to schedule another appointment.",
        es:
            "Usted recibió la vacuna contra el REPLACE_VACCINE_NAME. Como es una vacuna de una sola dosis, usted ha completado su vacunación y no es elegible para programar otra cita.",
    },
    closeBrowser: {
        en: "You can now close your browser.",
        es: "Ya puede cerrar su navegador.",
    },
    noappointment: {
        en: "Not ready to schedule an appointment?",
        es: "¿No está listo para programar una cita?",
    },
    urlnoappt: {
        en: "Enter your information to pre-register.",
        es: "Ingrese su información para preinscribirse.",
    },
    clinicVaccineError: {
        en:
            "This clinic does not carry the vaccine you need for your second dose. Search for another clinic location to schedule a vaccine appointment.",
        es:
            "Este centro médico no dispone de la vacuna que usted necesita para la segunda dosis. Busque otro lugar para programar una cita de vacunación.",
    },
    scheduleVaccinationAppt: {
        en: "Schedule your vaccination appointment",
        es: "Programar cita de vacunación",
    },
    scheduleVaccinationApptLabel: {
        en: "Schedule your **vaccination appointment** by finding a clinic and a time slot that works for you.",
        es: "Encuentre un centro médico y un horario conveniente para programar su primera cita.",
    },
    schedulePediatricVaccinationAppt: {
        en: "Schedule a Pediatric COVID-19 vaccine appointment (age 5-11)",
        es: "Programar una cita para la vacuna pediátrica contra el COVID-19 (5 a 11 años)",
    },
    schedulePediatricVaccinationApptLabel: {
        en:
            "Schedule a **pediatric** COVID-19 vaccine appointment for children **5-11** years old by finding a clinic and a timeslot that works for you.",
        es:
            "Programe una cita para la vacuna **pediátrica** contra el COVID-19, para niños **de 5 a 11** años. Encuentre un centro de vacunación y un horario que sean convenientes para usted.",
    },
    pediatricDoseNote: {
        en:
            "According to CDC recommendations, Children 5-11 years old are able to get the Pediatric Pfizer-BioNTech COVID-19 vaccine. *Please refer to the CDC guidelines prior to scheduling an appointment.*",
        es:
            "Según las recomendaciones de los CDC, los niños de 5 a 11 años pueden recibir la vacuna pediátrica contra el COVID-19 Pfizer-BioNTech. *Consulte las directrices de los CDC antes de programar una cita.*",
    },
    pediatricDescOne: {
        en:
            "Children 5-11 years old are able to get the Pediatric Pfizer-BioNTech COVID-19 vaccine. Click here to find out more. [Click here to find out more](https://www.cdc.gov/vaccines/covid-19/planning/children.html).",
        es:
            "Los niños de 5 a 11 años pueden recibir la vacuna pediátrica contra el COVID-19 Pfizer-BioNTech. [Haga clic aquí para obtener más información](https://www.cdc.gov/vaccines/covid-19/planning/children.html).",
    },
    pediatricDescTwo: {
        en:
            "Representative/guardian information is required for scheduling a Pediatric COVID-19 vaccination appointment.",
        es:
            "Se requiere la información de un representante o tutor legal para programar la cita para la vacuna pediátrica contra el COVID-19.",
    },
    guestQuestionNote: {
        en:
            "According to CDC recommendations, only the Pfizer-BioNTech and Moderna vaccines are available as an [additional dose](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html), and the Pfizer-BioNTech, Moderna, and Janssen (Johnson & Johnson) vaccine(s) as a [booster](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html). Children 5-11 years old are able to get the Pediatric Pfizer-BioNTech COVID-19 vaccine. *Please refer to the CDC guidelines prior to scheduling an appointment.*",
        es:
            "De acuerdo con las recomendaciones de los CDC, solamente las vacunas Pfizer y Moderna están disponibles para [la dosis adicional](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html), y las vacunas Pfizer, Moderna y Jansen (Johnson & Johnson) para la dosis de [refuerzo](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html). *Consulte las directrices de los CDC antes de programar una cita.*",
    },
    guestQuestionOne: {
        en:
            "COVID-19 Vaccine additional doses are available for moderately to severely immunocompromised populations who completed their initial series at least 28 days ago. [Click here to find out more](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html).",
        es:
            "De acuerdo con las recomendaciones de los CDC, solamente las vacunas Pfizer y Moderna están disponibles para [la dosis adicional](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html), y las vacunas Pfizer, Moderna y Jansen (Johnson & Johnson) para la dosis de [refuerzo](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html). *Consulte las directrices de los CDC antes de programar una cita.*",
    },
    guestQuestionTwo: {
        en:
            "COVID-19 Vaccine booster shots are available for the Pfizer-BioNTech and Moderna vaccine recipient who have completed their initial series at least 6 months (180 days) and Janssen (Johnson & Johnson) vaccine recipients who completed their initial series at least 2 months (60 days). [Click here to find out more](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html).",
        es:
            "De acuerdo con las recomendaciones de los CDC, solamente las vacunas Pfizer y Moderna están disponibles para [la dosis adicional](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html), y las vacunas Pfizer, Moderna y Jansen (Johnson & Johnson) para la dosis de [refuerzo](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html). *Consulte las directrices de los CDC antes de programar una cita.*",
    },
    guestQuestionThree: {
        en:
            "Children 5-11 years old are able to get the Pediatric Pfizer-BioNTech COVID-19 vaccine. [Click here to find out more](https://www.cdc.gov/vaccines/covid-19/planning/children.html).",
        es: "Children 5-11 years old are able to get the Pediatric Pfizer-BioNTech",
    },
    guestQuestionFour: {
        en:
            "Representative/guardian information is required for scheduling a Pediatric COVID-19 vaccination appointment.",
        es:
            "Representative/guardian information is required for scheduling a Pediatric COVID-19 vaccination appointment.",
    },
    additionalDoseBoosterNote: {
        en:
            "According to CDC recommendations, only the Pfizer and Moderna vaccines are available as an [additional dose](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html), and Pfizer, Moderna, and Janssen (Johnson & Johnson) vaccines as a [booster](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html). *Please refer to the CDC guidelines prior to scheduling an appointment.*",
        es:
            "De acuerdo con las recomendaciones de los CDC, solamente las vacunas Pfizer y Moderna están disponibles para [la dosis adicional](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html), y las vacunas Pfizer, Moderna y Jansen (Johnson & Johnson) para la dosis de [refuerzo](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html). *Consulte las directrices de los CDC antes de programar una cita.*",
    },
    additionalDesc: {
        en:
            "COVID-19 Vaccine additional doses are available for moderately to severely immunocompromised populations who completed their initial series at least 28 days ago. [Click here to find out more](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html).",
        es:
            "Las dosis adicionales de la vacuna contra el COVID-19 están disponibles para las poblaciones moderada a gravemente inmunodeprimidas que completaron la serie inicial hace al menos 28 días. [Haga clic aquí para obtener más información](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html).",
    },
    boosterDesc: {
        en:
            " COVID-19 Vaccine booster shots are available for Pfizer-BioNTech and Moderna vaccine recipients who have completed their initial series at least 6 months (180 days) and Janssen (Johnson & Johnson) vaccine recipients who completed their initial series at least 2 months (60 days) [Click here to find out more](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html).",
        es:
            "Las dosis de refuerzo de la vacuna contra el COVID-19 están disponibles para los que recibieron las vacunas Pfizer-BioNTech y Moderna y que completaron la serie inicial hace al menos 6 meses (180 días) y para los que recibieron la vacuna Janssen (Johnson & Johnson) y que completaron la serie inicial hace al menos 2 meses (60 días). [Haga clic aquí para obtener más información sobre su elegibilidad](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html).",
    },
    attestation: {
        en: "By checking this box, I confirm the above information is accurate.",
        es: "Al marcar esta casilla, confirmo que la información anterior es exacta.",
    },
    attestationAdditionalDose: {
        en:
            " By checking this box, I confirm that the above information is accurate. I attest that I meet the eligibility criteria to receive COVID-19 additional dose and that I am fully vaccinated.",
        es:
            " Al marcar esta casilla, confirmo que la información anterior es correcta. Confirmo que cumplo con los criterios de elegibilidad para recibir una dosis adicional de la vacuna contra el COVID-19 y que estoy completamente vacunado.",
    },
    attestationBoosterlDose: {
        en:
            "By checking this box, I confirm that the above information is accurate. I attest that I meet the eligibility criteria to receive the booster dose and I am fully vaccinated.",
        es:
            "Al marcar esta casilla, confirmo que la información anterior es correcta. Confirmo que cumplo con los criterios de elegibilidad para recibir la dosis adicional y que estoy completamente vacunado.",
    },
    boosterWrongVaccineError: {
        en:
            " COVID-19 Vaccine booster shots are available for Pfizer-BioNTech and Moderna vaccine recipients who have completed their initial series at least 6 months (180 days) and Janssen (Johnson & Johnson) vaccine recipients who completed their initial series at least 2 months (60 days).",
        es:
            "Las dosis de refuerzo de la vacuna contra el COVID-19 están disponibles para algunas personas que recibieron la vacuna Pfizer-BioNTech y Moderna que completaron la serie inicial de dosis hace al menos 6 meses (180 días) y Janssen (Johnson & Johnson) que completaron la serie inicial de dosis hace al menos 2 meses (60 días).",
    },
    boosterDateWarning: {
        en:
            "Please note: COVID-19 Vaccine booster shots are available for Pfizer-BioNTech and Moderna vaccine recipients who have completed their initial series at least 6 months (180 days) and Janssen (Johnson & Johnson) vaccine recipients who completed their initial series at least 2 months (60 days). Click 'Next' to proceed",
        es:
            "Tenga en cuenta lo siguiente: Están disponibles las dosis de refuerzo de la vacuna contra el COVID-19 para los que recibieron las vacunas Pfizer-BioNTech y Moderna que completaron la serie inicial hace al menos 6 meses (180 días), y para los que recibieron la vacuna Janssen (Johnson & Johnson) y que completaron la serie inicial de una dosis hace al menos 2 meses (60 días). Haga clic aquí para obtener más información. Haga clic en 'Siguiente' para proceder",
    },
};

export function GetLabelForLanguage(id: LabelId, lang: LanguageId = "en"): string {
    return Labels[id][lang];
}
