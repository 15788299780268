import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import 'semantic-ui-css/semantic.min.css'

// (window as any).getCaptchaToken = () => {
//   console.error("Google recaptcha is not initialized. Check google API settings");
//   return "invalid"
// }
// const Recaptcha = (callback:any) => {
//       const existingScript = document.getElementById('recaptchaSdk');
//       if(process.env.REACT_APP_RECAPTCHA_SITE_KEY === undefined) {
//         console.error("Recaptcha key not defined!");
//       }
//       if (!existingScript) {
//           const script = document.createElement('script');
//           script.src =
//               'https://www.google.com/recaptcha/enterprise.js?render=' + process.env.REACT_APP_RECAPTCHA_SITE_KEY;
//           script.id = 'recaptchaSdk';
//           document.body.appendChild(script);
//           script.onload = () => {
//               if (callback) callback();
//           };
//       }
//       if (existingScript && callback) callback();
// };

// Recaptcha(() => {
//   if((window as any).grecaptcha !== undefined && (window as any).grecaptcha.enterprise !== undefined) {
//     (window as any).grecaptcha.enterprise.ready(function() {
//         const generateCaptcha = async () => {
//               return await (window as any).grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {action: 'fr_survey'})
//         }
//         (window as any).getCaptchaToken = generateCaptcha;
//     });
//   }
// });



ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
