import React, { useContext } from "react";
import { Grid, Header, Icon } from "semantic-ui-react";
import AppContext from "../contexts/AppContext";
import { UrlHelper } from "../shared/url.helper";

export function VaccinationComplete() {
    const ctx = useContext(AppContext);
    const vaccineName = UrlHelper.get("vaccinename");
    const vaccine: any = ctx.config.vaccines.find((x) => x.value === vaccineName);

    const completionDesc = ctx
        .getLabel("vaccineCompleteDescription")
        .replace("REPLACE_VACCINE_NAME", vaccine[ctx.lang]);
    return (
        <Grid container>
            <Grid.Row>
                <section className="vaccination-complete">
                    <Icon color="green" name="check circle" size="huge" />
                    <Header as="h1">{ctx.getLabel("vaccineComplete")}</Header>
                    <p>{completionDesc} </p>
                    <b>{ctx.getLabel("closeBrowser")}</b>
                </section>
            </Grid.Row>
        </Grid>
    );
}
