import React, { useContext } from "react";
import { Button, Grid, Icon, Image, Popup } from "semantic-ui-react";
import AppContext from "../../../contexts/AppContext";
import { UrlHelper } from "../../../shared/url.helper";
import "./Header.css";

const style = {
    background: "#022748",
    color: "white"
  }

export function CoBrandHeader() {
    const ctx = useContext(AppContext);

    const toggleLanguage = () => {
        const next = ctx.lang === "es" ? "en" : "es";
        const queryString = UrlHelper.upsertParams({
            lang: next,
            jurisdiction: ctx.jurisdiction.id,
        });
        window.location.href = `${window.location.origin}${window.location.pathname}${queryString}`;
    };

    return (
        <div className="cobrand-header">
            <Grid container columns={1}>
                <Grid.Row verticalAlign="middle">
                    <Grid.Column>
                        <header className="responsive-header">
                            <div className="header-branding" role="heading" aria-level={1}>
                                <Image size="small" src={ctx.jurisdiction.logo} alt="Jurisdiction Logo" />
                                <Image size="small" src="logo.svg" alt="VAMS Logo" />
                            </div>
                            {ctx.jurisdiction.languageEnabled && (<div className='language-wrapper'>
                                <Popup
                                    inverted
                                    style={style}
                                    content={ctx.getJurisdictionLabel("languageTooltip")}
                                    trigger={
                                        <Button
                                            color="grey"
                                            basic
                                            icon
                                            labelPosition="left"
                                            className="langButton"
                                            onClick={toggleLanguage}
                                        >
                                            <Icon name="globe" />
                                            {ctx.lang === "es" ? "English" : "Español"}
                                        </Button>
                                    }
                                />
                            </div>)}
                        </header>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}
