import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { Button, Form, Grid, Header, Message, Segment } from "semantic-ui-react";
import AppContext from "../contexts/AppContext";
import {
    VaccineDateQuestion,
    JurisdictionQuestion,
    VaccineReceivedQuestion,
    VaccineNameQuestion,
    IsRepresentativeQuestion,
    ApptTypeQuestion,
    VaccineICQuestion,
    AdditionalDoseScheduledQuestion,
    PediatricQuestion,
    VaccineNameQuestionPediatric,
} from "./base/Questions";
import * as Yup from "yup";
import moment from "moment";
import { useHistory } from "react-router";
import { UrlHelper } from "../shared/url.helper";
import ReactMarkdown from "react-markdown";

interface FormData {
    jurisdiction: string;
    doseType: "booster" | "additional" | "no" | "";
    vaccineReceived: "yes" | "no" | "";
    isRepresentative: "yes" | "no" | "";
    vaccineName: string;
    vaccineDate: Date;
    attestationChecked: boolean;
    apptType: string;
    vaccineICName: string[];
    pediatricQuestion: "yes" | "no" | "";
}

export function GuestForm() {
    const history = useHistory();
    const [disabled, setDisabled] = useState(false);

    const [message, setMessage] = useState<{
        header: string;
        body: string;
        confirmation?: string;
        showOverwrite?: boolean;
        error?: boolean;
        success?: boolean;
        warning?: boolean;
    }>(null);

    const ctx = useContext(AppContext);

    const onSubmit = async (values: FormData) => {
        try {
            setDisabled(true);
            setMessage(null);
            const vaccineType = values.vaccineICName.join("|");

            if (values.vaccineName === "janssen" && values.vaccineReceived === "yes") {
                const qStr = UrlHelper.upsertParams({
                    vaccinename: values.vaccineName,
                    vaccineType,
                });
                history.push(`/singleDoseVaccine${qStr}`);
                return;
            }

            // Send them to the right url for apptType
            const url = new URL(
                values.apptType === "preregister" || ctx.clinicType.toLocaleLowerCase() === "tp"
                    ? ctx.config.manageApptsUrl
                    : ctx.config.guestUrl
            );

            let dose =
                values.doseType === "additional" || values.doseType === "booster"
                    ? "3"
                    : values.vaccineReceived === "yes"
                    ? "2"
                    : "1";

            url.searchParams.append("jurisdiction", values.jurisdiction);
            url.searchParams.append("vaccineType", vaccineType);
            if (values.vaccineICName.includes("minor") && values.vaccineICName.includes("COVID")) {
                url.searchParams.append("ageCategory", "minor");
                dose = values.pediatricQuestion === "no" ? "1" : "2";
            }

            url.searchParams.append("dose", dose);
            // url.searchParams.append("isRepresentative", values.isRepresentative);

            // Only send vaccine info if we're on dose 2 or more
            if (parseInt(dose) >= 2) {
                url.searchParams.append("vaccinename", values.vaccineName);
                url.searchParams.append("initialvaccinationdate", moment(values.vaccineDate).format("L"));
            }

            // Only send clinicID if its present
            if (ctx.clinicID) {
                url.searchParams.append("clinicID", ctx.clinicID);
            }

            // Only send locationID if its present
            if (ctx.locationID) {
                url.searchParams.append("locationID", ctx.locationID);
            }

            url.searchParams.append("language", ctx.lang === "en" ? "en_US" : ctx.lang);

            // Configure redirct for prereg
            if (values.apptType === "preregister") {
                url.searchParams.append("redirect", "registerRecipient");
            }

            if (values.doseType !== "no") {
                url.searchParams.append("doseType", values.doseType);
            }

            window.location.href = url.toString();
        } catch (error) {
            console.log(error);
            setMessage({ error: true, header: "Error", body: error.message });
            setDisabled(false);
        }
    };

    const defaultValues = (): FormData => {
        return {
            jurisdiction: ctx.jurisdiction.id,
            pediatricQuestion: "",
            doseType: "",
            vaccineReceived: "",
            isRepresentative: "",
            vaccineName: "",
            vaccineDate: undefined,
            attestationChecked: false,
            apptType: "",
            vaccineICName: ["adult"],
        };
    };

    const formik = useFormik({
        initialValues: defaultValues(),
        validationSchema: Yup.object({
            jurisdiction: Yup.string().required(ctx.getLabel("requiredFieldError")),
            vaccineICName: Yup.array().min(2, ctx.getLabel("requiredFieldError")),
            doseType: Yup.string().when("vaccineICName", {
                is: (vicn: string[]) => {
                    return vicn.includes("COVID") && vicn.includes("adult");
                },
                then: Yup.string().required(ctx.getLabel("requiredFieldError")),
            }),
            pediatricQuestion: Yup.string().when("vaccineICName", {
                is: (vicn: string[]) => {
                    return vicn.includes("COVID") && vicn.includes("minor");
                },
                then: Yup.string().required(ctx.getLabel("requiredFieldError")),
            }),
            vaccineReceived: Yup.string().when("vaccineICName", {
                is: (vicn: string[]) => {
                    return vicn.includes("COVID") && vicn.includes("adult");
                },
                then: Yup.string().when("doseType", {
                    is: "no",
                    then: Yup.string().required(ctx.getLabel("requiredFieldError")),
                }),
            }),
            vaccineName: Yup.string()
                .when("pediatricQuestion", {
                    is: "yes",
                    then: Yup.string().required(ctx.getLabel("vaccineNameRequiredError")),
                })
                .when("doseType", {
                    is: "booster",
                    then: Yup.string().required(ctx.getLabel("vaccineNameRequiredError")),
                }),
            vaccineDate: Yup.string()
                .when("vaccineICName", {
                    is: (vicn: string[]) => {
                        return vicn.includes("COVID") && vicn.includes("adult");
                    },
                    then: Yup.string()
                        .when("vaccineReceived", {
                            is: "yes",
                            then: Yup.string().required(ctx.getLabel("vaccineDateRequiredError")),
                        })
                        .when("doseType", {
                            is: "additional",
                            then: Yup.string().required(ctx.getLabel("vaccineDateRequiredError")),
                        })
                        .when("doseType", {
                            is: "booster",
                            then: Yup.string().required(ctx.getLabel("vaccineDateRequiredError")),
                        }),
                })
                .when("vaccineICName", {
                    is: (vicn: string[]) => {
                        return vicn.includes("COVID") && vicn.includes("minor");
                    },
                    then: Yup.string().when("pediatricQuestion", {
                        is: "yes",
                        then: Yup.string().required(ctx.getLabel("vaccineDateRequiredError")),
                    }),
                }),
            attestationChecked: Yup.boolean().required(ctx.getLabel("requiredFieldError")),
            apptType: Yup.string().test("apptTypeRequired", ctx.getLabel("requiredFieldError"), (value) => {
                if (ctx.clinicID === null) return true;
                return value !== undefined;
            }),
        }),
        onSubmit: onSubmit,
    });

    const boosterNote = () => (
        <div>
            <ReactMarkdown linkTarget="_blank">{ctx.getLabel("guestQuestionNote")}</ReactMarkdown>
            <ul>
                <li>
                    <ReactMarkdown linkTarget="_blank">{ctx.getLabel("guestQuestionOne")}</ReactMarkdown>
                </li>
                <li>
                    <ReactMarkdown linkTarget="_blank">{ctx.getLabel("guestQuestionTwo")}</ReactMarkdown>
                </li>
                <li>
                    <ReactMarkdown linkTarget="_blank">{ctx.getLabel("guestQuestionThree")}</ReactMarkdown>
                </li>
                <li>
                    <ReactMarkdown linkTarget="_blank">{ctx.getLabel("guestQuestionFour")}</ReactMarkdown>
                </li>
            </ul>
        </div>
    );

    const attestationText = () => {
        switch (formik.values.doseType) {
            case "additional":
                return ctx.getLabel("attestationAdditionalDose");
            case "booster":
                return ctx.getLabel("attestationBoosterlDose");
            default:
                return ctx.getLabel("attestation");
        }
    };

    return (
        <Grid container>
            <Grid.Row>
                <Grid.Column>
                    <Header as="h1">{ctx.getJurisdictionLabel("welcome")}</Header>
                    <Message icon="info circle" content={boosterNote()} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Form success className="eligibility-form" onSubmit={formik.handleSubmit}>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Segment padded>
                                        <Grid columns={1}>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <JurisdictionQuestion
                                                        formik={formik as any}
                                                        disabled={disabled}
                                                        tabIndex={1}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <VaccineICQuestion
                                                        formik={formik as any}
                                                        disabled={disabled}
                                                        tabIndex={2}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>

                                            {formik.values.vaccineICName.includes("minor") &&
                                                formik.values.vaccineICName.includes("COVID") && (
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <PediatricQuestion
                                                                formik={formik as any}
                                                                disabled={disabled}
                                                                tabIndex={3}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )}

                                            {formik.values.vaccineICName.includes("COVID") &&
                                                formik.values.vaccineICName.includes("adult") && (
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <AdditionalDoseScheduledQuestion
                                                                formik={formik as any}
                                                                disabled={disabled}
                                                                tabIndex={4}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )}

                                            {formik.values.doseType === "no" &&
                                                formik.values.vaccineICName.includes("COVID") &&
                                                formik.values.vaccineICName.includes("adult") && (
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <VaccineReceivedQuestion
                                                                formik={formik as any}
                                                                disabled={disabled}
                                                                tabIndex={5}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )}

                                            {formik.values.pediatricQuestion === "yes" &&
                                                formik.values.vaccineICName.includes("minor") &&
                                                formik.values.vaccineICName.includes("COVID") && (
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <VaccineNameQuestionPediatric
                                                                formik={formik as any}
                                                                disabled={disabled}
                                                                tabIndex={6}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )}

                                            {(formik.values.vaccineReceived === "yes" ||
                                                formik.values.doseType === "booster") &&
                                                formik.values.vaccineICName.includes("adult") &&
                                                formik.values.vaccineICName.includes("COVID") && (
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <VaccineNameQuestion
                                                                formik={formik as any}
                                                                disabled={disabled}
                                                                tabIndex={7}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )}

                                            {((formik.values.vaccineICName.includes("minor") &&
                                                formik.values.pediatricQuestion === "yes") ||
                                                (formik.values.vaccineICName.includes("adult") &&
                                                    (formik.values.doseType === "additional" ||
                                                        formik.values.doseType === "booster" ||
                                                        formik.values.vaccineReceived === "yes"))) &&
                                                formik.values.vaccineICName.includes("COVID") && (
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <VaccineDateQuestion
                                                                formik={formik as any}
                                                                disabled={disabled}
                                                                tabIndex={8}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )}

                                            {ctx.jurisdiction.enableAuthRep && (
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <IsRepresentativeQuestion
                                                            formik={formik as any}
                                                            disabled={disabled}
                                                            tabIndex={9}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}
                                            {ctx.clinicID && (
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <ApptTypeQuestion
                                                            formik={formik as any}
                                                            disabled={disabled}
                                                            tabIndex={10}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Form.Checkbox
                                                        label={attestationText()}
                                                        aria-label={attestationText()}
                                                        disabled={disabled}
                                                        checked={formik.values.attestationChecked}
                                                        onClick={async () => {
                                                            if (!formik.isValid) {
                                                                const errorFields = {} as any;
                                                                Object.keys(formik.errors).forEach(
                                                                    (key) => (errorFields[key] = true)
                                                                );
                                                                formik.setTouched(errorFields, true);
                                                            }
                                                            formik.setFieldValue(
                                                                "attestationChecked",
                                                                !formik.values.attestationChecked
                                                            );
                                                        }}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            {message && (
                                                <Message
                                                    visible={message ? true : false}
                                                    error={message?.error}
                                                    success={message?.success}
                                                >
                                                    <Message.Header>{message?.header}</Message.Header>
                                                    {message?.body && <p>{message.body}</p>}
                                                </Message>
                                            )}
                                        </Grid>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column textAlign={"right"}>
                                    <Button primary disabled={!formik.values.attestationChecked} type="submit">
                                        {ctx.getLabel("next")}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
