/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import { Grid } from "semantic-ui-react";

export interface ILabelProps {
  label: string,
  subLabel?: string,
  subLabelLink?: string,
  required?: boolean,
  className?: string,
  id?: string;
  children?: any;
}
export function AppLabel(props: ILabelProps){

  const subLabel = (props.subLabel && props.subLabelLink)
    ? <p><small><a href={props.subLabelLink} target="_blank">{props.subLabel}</a></small></p>
    : props.subLabel
      ? <p><small>{props.subLabel}</small></p>
      : null

  return (
    <>
      <label id={props.id} className={props.className || "app-label"}>{props.required && <span className="required-field">*</span>}{props.label}
        {subLabel}
        {props.children && <Grid.Row>{props.children}</Grid.Row>}
      </label>
    </>);
}