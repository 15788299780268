export class UrlHelper {
    static get(key?: string) {
        const queryString = window.location.search;
        let query: any = {};
        let pairs = (queryString[0] === "?" ? queryString.substr(1) : queryString).split("&");
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split("=");
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
        }
        if (key) {
            return query[key];
        }
        return query;
    }
    static upsertParams(obj: Object) {
        const prev = UrlHelper.get();
        const next = Object.assign(prev, obj);
        return UrlHelper.toQueryString(next);
    }
    static toQueryString(obj: { [k: string]: string }) {
        const keys = Object.keys(obj).filter(k => !!obj[k]);
        if (keys && keys.length > 0) {
            return keys.reduce((acc: string, curr: string, i: number) => {
                if (i === 0) {
                    return `${acc}${curr}=${obj[curr]}`;
                }
                return `${acc}&${curr}=${obj[curr]}`;
            }, "?");
        }
        return '';
    }
}
