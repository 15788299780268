import React, { useContext } from "react";
import { Grid } from "semantic-ui-react";
import "./App.css";
import { Header } from "./components/base/Header/Header";
import { LandingPage } from "./components/LandingPage";
import { AppContextProvider } from "./contexts/AppContextProvider";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { AdditionalDose } from "./components/AdditionalDose";
import { PediatricDose } from "./components/PediatricDose";
import { GuestForm } from "./components/GuestForm";
import { VaccinationComplete } from "./components/VaccinationComplete";
import { SingleDoseVaccine } from "./components/SingleDoseVaccine";
import { Footer } from "./components/base/Footer/Footer";
import { CoBrandHeader } from "./components/base/Header/CoBrandHeader";
import AppContext from "./contexts/AppContext";

/**
 * Choose header based on config file
 */
function CustomHeader() {
    const ctx = useContext(AppContext);
    if (!ctx.jurisdiction.logo) {
        return <Header />;
    } else {
        return <CoBrandHeader />;
    }
}

export default function App() {
    const renderContent = (component: any) => (
        <>
            <Header />
            <main>{component}</main>
        </>
    );
    return (
        <AppContextProvider>
            <Router>
                <Switch>
                    <Route path="/vaccinationComplete" component={() => renderContent(<VaccinationComplete />)} />
                    <Route path="/additionalDose" component={() => renderContent(<AdditionalDose />)} />
                    <Route path="/pediatricDose" component={() => renderContent(<PediatricDose />)} />
                    <Route path="/guest" component={() => renderContent(<GuestForm />)} />
                    <Route path="/singleDoseVaccine" component={() => renderContent(<SingleDoseVaccine />)} />
                    <Route
                        path="/"
                        component={() => (
                            <>
                                <CustomHeader />
                                <main>
                                    <LandingPage />
                                </main>
                            </>
                        )}
                    />
                </Switch>
                <Footer />
            </Router>
        </AppContextProvider>
    );
}
